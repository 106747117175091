import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

function App() {
  const [celsius, setCelsius] = useState('');
  const [fahrenheit, setFahrenheit] = useState('')
  const [error, setError] = useState('')

  const handleChange = (event) => {
    // console.log("event is ", event)
    const { name , value } = event.target
    // console.log("name is ", name, " value is ", value)
    
    if (value.length == 0) {
      setCelsius('')
      setFahrenheit('')
      return
    }

    if (name == 'celsius') {
      const validNumber = validateStringIsNumeric(value)
      
      if (!validNumber) {
        setFahrenheit("ERROR")
      } else {
        const fahrenheit = convertCelsiusToFahrenheit(value)
        setFahrenheit(fahrenheit)
      }
      
      setCelsius(value)
    } else {
      const validNumber = validateStringIsNumeric(value)
      if (!validNumber) {
        setCelsius("ERROR")
      } else {
        const celsius = fahrenheitToCelsius(value)
        setCelsius(celsius)
      }

      setFahrenheit(value)
    }
  };

  const validateStringIsNumeric = (str) => {
    if (str.length == 0) {
      return true
    }
    const result = !isNaN(str) && !isNaN(parseFloat(str));
    // console.log("is string numeric ", result)
    return result
  }

  const convertCelsiusToFahrenheit = (celsius) => {
    return (celsius * 9/5) + 32;
  }

  function fahrenheitToCelsius(fahrenheit) {
    return (fahrenheit - 32) * 5/9;
  }
  
  return (
    <div className="App">
        <p>
          Temperature converter
        </p>
        <input 
        name='celsius'
        type="text" 
        value={celsius}
        onChange={handleChange} 
        placeholder="Celsius..." 
      />
      <input 
        type="text" 
        name="fahrenheit"
        value={fahrenheit}
        onChange={handleChange} 
        placeholder="Fahrenheit..." 
      />
    </div>
  );
}

export default App;
